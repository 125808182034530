<template>
  <div>
    <con-tab @TwoClick="TwoClick"></con-tab>
    <!-- 服务发布 -->
    <ReleaseNei></ReleaseNei>
  </div>
</template>

<script>
import ConTab from "../../components/common/ConTab";
import ReleaseNei from "./base/ReleaseNei";
// import Qs from 'qs'
export default {
  name: "release",
  data() {
    return {};
  },
  created() {},
  methods: {
    TwoClick(item) {
      //console.log(item);
      this.ConList = item;
    },
  },
  components: {
    ConTab,
    ReleaseNei,
  },
};
</script>

<style scoped>
</style>
