<template>
  <div class="bc">
    <div class="release w">
      <div class="release_con">
        <div class="pea-title">
          <div class="one-content">
            <div class="copy-title">
              <div class="copy-con copy_con">
                <span></span>
                <h3>服务发布</h3>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <el-row class="title">
          <span class="one">1</span>
          <span>服务基本信息</span>
        </el-row>
        <el-form
          label-width="80px"
          :model="release"
          :label-position="labelPosition"
        >
          <el-form-item label="服务图片">
            <el-upload
              :on-change="fnI"
              action=""
              list-type="picture-card"
              :on-preview="handlePictureCardPreviewG"
              :on-remove="handleRemoveG"
              :auto-upload="false"
              accept="image/*"
              :class="{ hide: hideUploadG }"
              class="avatar-uploader"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item>
          <el-form-item label="服务名称">
            <el-input
              v-model="release.title"
              placeholder="请填写服务名称"
            ></el-input>
          </el-form-item>
            <el-form-item label="服务电话">
            <el-input maxlength='11'
              v-model="release.phone"
              placeholder="请填写服务电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="服务描述">
            <!-- <el-input
              v-model="release.describe"
              placeholder="请填写服务描述"
            ></el-input> -->
              <el-input
             type="textarea"
              :rows="5"
              v-model="release.describe"
              placeholder="请填写服务描述"
            ></el-input>
          </el-form-item>
          <el-form-item label="服务费用">
            <el-input style="width: 95%; margin-right: 10px;"
              v-model="release.cost"  type="number"
              placeholder="请填写服务费用"
            ></el-input><span>元</span>
          </el-form-item>
          <el-form-item label="服务响应时间">
            <el-input
              v-model="release.response_time"
              placeholder="请填写服务响应时间"
            ></el-input>
          </el-form-item>

          <el-form-item label="服务类型">
            <el-radio-group v-model="release.type">
              <el-radio
                :label="item.id"
                v-for="item in type"
                :key="item.index"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-form>
        <el-row class="title">
          <span class="one">2</span>
          <span>服务说明信息</span>
        </el-row>
        <el-form
          label-width="80px"
          :model="release"
          :label-position="labelPosition"
        >
          <el-form-item label="服务说明">
            <el-input
             type="textarea"
              :rows="5"
              v-model="release.explain"
              placeholder="请填写服务说明"
            ></el-input>
          </el-form-item>
          <el-form-item label="服务流程">
            <!-- <div class="fb_img">
              <img src="../../../assets/img/kefuyun/fb.png" alt="" />
            </div> -->
            <el-upload
              :on-change="fn1"
              class="upload-demo"
              ref="upload"
              action=""
              :file-list="fileList"
              :auto-upload="false"
              :limit="1"
              :on-remove="handleRemove1"
              :on-exceed="handleExceed1"
              list-type="picture"
              accept="image/*"
            >
              <el-button plain size="mini">上传图片</el-button>
              <div slot="tip" class="el-upload__tip">
                支持扩展名：.png .jpg...
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="服务优势">
            <!-- <div class="fb_img">
              <img src="../../../assets/img/kefuyun/fb.png" alt="" />
            </div> -->

            <el-upload
              :on-change="fn"
              class="upload-demo"
              ref="upload"
              action=""
              :file-list="fileList"
              :auto-upload="false"
              :limit="1"
              :on-remove="handleRemove"
              :on-exceed="handleExceed"
              list-type="picture"
              accept="image/*"
            >
              <el-button plain size="mini">上传图片</el-button>
              <div slot="tip" class="el-upload__tip">
                支持扩展名：.png .jpg...
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="服务保障">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请输入内容"
              v-model="release.advantage"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="subtmit-btn-box">
          <el-button type="primary" @click="onRelease">发布</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Addserver, Type } from "../../../common/js/api";
import { uploadImg } from "../../../common/img/apiimg";
import { handleCompressImg } from "../../../api";
export default {
  name: "ReleaseNei",
  data() {
    return {
      labelPosition: "left",
      release: {
        member_id: "",
        img: "",
        title: "",
        phone:'',
        describe: "",
        response_time: "",
        cost: "",
        type: "",
        explain: "",
        process: "",
        guarantee: "",
        advantage: "",
      },
      dialogVisible: false,
      dialogImageUrl: "",
      hideUploadG: false,
      fileList: [],
      type: [],
    };
  },
  created() {
    this.getType();
    this.release.member_id = JSON.parse(localStorage.getItem("user")).id;
  },
  methods: {
    // 文本域带格式
preText (pretext) {
    return pretext.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;')
},
    // 获取服务类型
    getType() {
      Type()
        .then((res) => {
          console.log(res);
          this.type = res.data;
        })
        .catch(() => {});
    },
    // 发布
    onRelease() {
       this.release.describe = this.preText(this.release.describe);
      this.release.explain = this.preText(this.release.explain);
      this.release.advantage = this.preText(this.release.advantage);
      // console.log(this.release);
      Addserver(this.release)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    // 服务图片
    fnI(file, fileList) {
      this.hideUploadG = fileList.length >= 1;
      handleCompressImg(file.raw).then((result) => {
        // console.log(result);
        this.Up(result, 1);
      });
    },
    //将图片地址上传
    Up(file) {
      let formData = new FormData();
      formData.append("file", file);

      uploadImg(formData)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("上传成功");
            this.release.img = res.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {});
    },
    handleRemoveG(file, fileList) {
      this.hideUploadG = fileList.length >= 1;
    },
    handlePictureCardPreviewG(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //
    // 服务优势 新增   上传文件
    fn(file, fileList) {
      console.log(this.fileList);
      this.up(file.raw, fileList);
    },
    // 删除文件
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.up(file.raw, fileList);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    up(file, fileList) {
      let formData = new FormData();
      formData.append("file", file);
      uploadImg(formData)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message.success("上传成功");
            this.release.guarantee = res.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {});
    },

    //
    // 服务流程 新增   上传文件
    fn1(file, fileList) {
      console.log(this.fileList);
      this.up1(file.raw, fileList);
    },
    // 删除文件
    handleRemove1(file, fileList) {
      console.log(file, fileList);
      this.up(file.raw, fileList);
    },
    handleExceed1(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    up1(file, fileList) {
      let formData = new FormData();
      formData.append("file", file);
      uploadImg(formData)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message.success("上传成功");
            this.release.process = res.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {});
    },

    //
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.release .fb_img {
  display: inline-block;
  width: 200px;
  height: 120px;
  vertical-align: bottom;
  
}
.release .subtmit-btn-box {
  margin-top: 70px;
  display: flex;
  justify-content: center;
}
/deep/.hide .el-upload--picture-card {
  display: none;
}
/deep/.el-form {
  padding-left: 40px;
}
/deep/.el-textarea__inner {
  resize: none;
}
/deep/.upload-demo {
  display: inline-block;
  margin-left: 10px;
}
/deep/.el-radio {
  margin-bottom: 10px;
}
</style>